<template>
  <b-card title="All Warranty">
    <b-modal
      id="promptDownload"
      ref="modal"
      v-model="promptDownload"
      title="Download"
      hide-footer
      @hidden="resetDownload"
    >
      <strong v-if="warrantyToDownload.length < metadata.totalData"
        >Generating Data to Download...</strong
      >
      <strong v-else
        >Loading data finished, total data is
        {{ warrantyToDownload.length }}</strong
      >
      <!-- <div>
      <strong>Loading Data... Progress {{ downPage }} of {{ this.metadata.totalPages }}</strong>
    </div> -->
      <!-- <div>
      <strong> Progress {{ dataDownload }} of {{ this.metadata.totalData }}</strong>
    </div> -->
      <div>
        <strong> Progress {{ progressDownload }} %</strong>
      </div>
      <div style="display: table; margin: 0 auto;">
        <b-button
          :disabled="warrantyToDownload.length < metadata.totalData"
          variant="primary"
          style="margin-top:20px"
        >
          <download-excel
            :data="warrantyToDownload"
            :fields="filter"
            name="AllRegWarranty.xls"
            class="download-border"
            >download</download-excel
          >
        </b-button>
      </div>
    </b-modal>
    <b-col cols="12">
      <b-row>
        <b-col cols="12" class="mb-2">
          <span><b>Filter by :</b></span>
          <div class="filter-responsive">
            <div class="input-group">
              <div class="input-group-prepend">
                <b-dropdown
                  style="margin: 0 !important;"
                  id="dropdown-1"
                  :text="selectedFilter"
                  class="m-md-2"
                >
                  <b-dropdown-item @click="dropDownMenuClick" field="fullName"
                    >Name</b-dropdown-item
                  >
                  <b-dropdown-item @click="dropDownMenuClick" field="email"
                    >Email</b-dropdown-item
                  >
                  <b-dropdown-item @click="dropDownMenuClick" field="phone"
                    >Phone</b-dropdown-item
                  >
                  <b-dropdown-item @click="dropDownMenuClick" field="certNo"
                    >Certificate</b-dropdown-item
                  >
                  <b-dropdown-item @click="dropDownMenuClick" field="barcode"
                    >Barcode</b-dropdown-item
                  >
                  <b-dropdown-item @click="dropDownMenuClick" field="postal"
                    >Postal</b-dropdown-item
                  >
                </b-dropdown>
              </div>
              <b-form-input
                v-if="selectedField !== 'modelandserial'"
                v-model="toFilter"
                placeholder="Filter.."
              />
            </div>
          </div>
          <span style="font-size: smaller; color: gray;"
            >Note: Please omit the last character of R or D of project warranty
            number (example: 2024062501285975AC)</span
          >
        </b-col>
        <b-col cols="12" class="mb-2">
          <span><b>Or</b></span>
          <div class="filter-responsive">
            <div class="input-group">
              <b-col cols="4">
                <span>Model No <span style="color:red">(*)</span></span>
                <b-form-input
                  v-model="toFilterModel"
                  placeholder="Add Model"
                ></b-form-input>
              </b-col>
              <b-col cols="4">
                <span>Serial No. <span style="color:red">(*)</span></span>
                <b-form-input
                  v-model="toFilterSerial"
                  placeholder="Add Serial"
                ></b-form-input>
              </b-col>
              <b-col cols="4">
                <span>Product Hierarchy</span>
                <b-form-input
                  v-model="toFilterPhlvl1"
                  placeholder="Add Serial"
                ></b-form-input>
              </b-col>
            </div>
            <b-button @click="doFilter" variant="primary">Filter</b-button>
            <b-button @click="clearFilter" variant="primary">Clear</b-button>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="filter-grid my-1">
            <!-- <div class="paperless-drop"> -->
            <!-- <label>Status</label>
              <b-form-select
                @change="(value) => fireFiltering('warrantyType', value)"
                v-model="warrantyType"
                :options="warrantyOptions"
              ></b-form-select> -->
            <!-- </div> -->

            <div class="paperless-drop ">
              <label>Years</label>
              <b-form-select
                @change="(value) => fireFiltering('year', value)"
                v-model="year"
                :options="yearOptions"
              ></b-form-select>
            </div>

            <div class="paperless-drop">
              <label>Month</label>
              <b-form-select
                @change="(value) => fireFiltering('month', value)"
                :disabled="year === null"
                v-model="month"
                :options="months"
              ></b-form-select>
            </div>

            <div class="d-flex">
              <b-button
                variant="primary"
                @click="openDownloadModal"
                :disabled="monthYearQueryExist"
              >
                Download
              </b-button>
            </div>
          </div>

          <b-table
            sticky-header="500px"
            :items="allWarranty"
            :fields="fields"
            :filter="search"
            :per-page="perPage"
            :busy.sync="busyLoadingWarranty"
            show-empty
            empty-text="Data Not Found"
            responsive
          >
            <!-- <template #cell(certNo)="row">
              <a
                href="#"
                @click.prevent="detailWarranty(row)"
                style="color: #6e6b7b; text-decoration: underline;"
              >
                {{ `${row.item.certNo}` }}
              </a>
            </template> -->
            <template #cell(dealerName)="row">
              {{
                row.item.dealer
                  ? row.item.dealer.dealerName
                  : row.item.dealerName
              }}
            </template>

            <template #cell(createdAt)="row">
              {{ formatCreatedAt(row.item.createdAt) }}
            </template>
            <template #cell(installationDate)="row">
              {{ formatCreatedAt(row.item.installationDate) }}
            </template>

            <template #cell(topDate)="row">
              {{ row.item.topDate | formatDate }}
            </template>
            <template #cell(residentialWarrantyEndDate)="row">
              <div v-if="row.item.residentialWarrantyEndDate">
                {{ generalDate(row.item.residentialWarrantyEndDate) }}
              </div>
            </template>
            <template #cell(generalWarrantyEndDate)="row">
              {{ generalDate(row.item.generalWarrantyEndDate) }}
            </template>
            <template #cell(mergeAddress)="row">
              {{ row.item.address }} {{ row.item.address2 }}
              {{ row.item.address3 }} {{ row.item.buildingName }}
            </template>
            <template #cell(compressorWarrantyEndDate)="row">
              <div v-if="row.item.compressorWarrantyEndDate">
                {{ compressorDate(row.item.compressorWarrantyEndDate) }}
              </div>
            </template>
            <template #cell(invoiceDate)="row">
              <div v-if="row.item.invoiceDate">
                {{ compressorDate(row.item.invoiceDate) }}
              </div>
            </template>
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>

            <!-- Action -->
            <template #cell(action)="row">
              <b-dropdown
                boundary="window"
                offset="-50"
                variant="primary"
                text="Menu"
                no-caret
              >
                <template #button-content>
                  <feather-icon size="1x" icon="MenuIcon" />
                </template>
                <b-dropdown-item
                  style="text-align:center"
                  v-if="row.item.ref == 'Project'"
                >
                  <b-button
                    variant="primary"
                    size="sm"
                    @click="letterDev(row.item.id)"
                  >
                    <feather-icon icon="PrinterIcon" /> Developer
                  </b-button>
                </b-dropdown-item>
                <b-dropdown-item
                  style="text-align:center"
                  v-if="row.item.ref == 'Project'"
                >
                  <b-button
                    variant="primary"
                    size="sm"
                    @click="letterResident(row.item.id)"
                  >
                    <feather-icon icon="PrinterIcon" /> Resident
                  </b-button>
                </b-dropdown-item>

                <b-dropdown-item
                  style="text-align:center"
                  v-if="row.item.ref == 'Paperless'"
                >
                  <b-button
                    variant="primary"
                    size="sm"
                    @click="detailWarranty(row)"
                  >
                    <feather-icon icon="PrinterIcon" /> Paperless
                  </b-button>
                </b-dropdown-item>
                <b-dropdown-item
                  style="text-align:center"
                  v-if="row.item.ref == 'Equipment'"
                >
                  <b-button
                    variant="primary"
                    size="sm"
                    @click="letterEquipment(row.item.id)"
                  >
                    <feather-icon icon="PrinterIcon" /> Equipment
                  </b-button>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-col>
        <b-col cols="12">
          <b-pagination
            :total-rows="totalData"
            :per-page="perPage"
            @change="onPageChange"
            v-model="reactiveCurrentPage"
            style="float : right;"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { userAccess, dateFormat, downloadByPDFMake } from "@/utils/utils";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import pdfMake from "pdfmake/build/pdfmake";
import {
  logo,
  footer,
  header,
} from "../delivery-module/actions-detail/daikin-img-base64";
import axios from "@/axios";

export default {
  data() {
    return {
      downPage: 0,
      dataDownload: 0,
      metadata: { totalPages: 0, totalData: 0, lastRegisterDate: null },
      allWarranties: [],
      selectedFilter: "Name",
      selectedField: "fullName",
      toFilter: "",
      busyLoadingWarranty: true,
      year: null,
      month: null,
      toFilterModel: "",
      toFilterSerial: "",
      toFilterPhlvl1: "",
      warrantyToDownload: [],
      promptDownload: false,
      totalData: 1,
      perPage: 10,
      search: "",
      currentPage: 1,
      warrantyType: null,
      yearOptions: [{ value: null, text: "All" }],
      form: {
        primary: [],
        secondary: [],
        module: "",
        subModule: "",
        name: "",
      },
      warrantyOptions: [
        { value: null, text: "All" },
        { value: "Registered", text: "Registered" },
        { value: "Cancelled", text: "Cancelled" },
        // { value: 'Registration Issue', text: 'Registration Issue' },
      ],
      months: [
        { value: null, text: "All" },
        { value: 1, text: "January" },
        { value: 2, text: "February" },
        { value: 3, text: "March" },
        { value: 4, text: "April" },
        { value: 5, text: "May" },
        { value: 6, text: "June" },
        { value: 7, text: "July" },
        { value: 8, text: "August" },
        { value: 9, text: "September" },
        { value: 10, text: "October" },
        { value: 11, text: "November" },
        { value: 12, text: "December" },
      ],
      fields: [
        {
          key: "action",
          label: "Action",
        },
        {
          key: "certNo",
          label: "Certificate",
        },
        {
          key: "barcode",
          label: "Barcode",
        },
        {
          key: "fullName",
          label: "Customer Name",
        },
        { key: "email", label: "Customer Email" },
        {
          key: "installerName",
          label: "Installer",
        },

        {
          key: "phone",
          label: "Phone",
        },

        { key: "itemModel", label: "Model" },
        { key: "serial", label: "Serial" },

        {
          key: "sapDo",
          label: "DO Number",
        },
        { key: "dealerCode", label: "Dealer Code" },
        { key: "dealerName", label: "Dealer Name" },

        {
          key: "installationDate",
          label: "Installation Date",
        },
        {
          key: "invoiceDate",
          label: "Invoice Date",
        },
        { key: "industryType", label: "Distribution Channel" },

        { key: "pcode", label: "PCode" },
        { key: "phlvl1", label: "Product Hierarchy" },

        // {
        //   key: "projectName",
        //   label: "Project Name",
        //   filterable: true,
        //   sortable: true,
        // },
        // {
        //   key: "remarks",
        //   label: "Extended Remarks",
        //   filterable: true,
        //   sortable: false,
        // },
        // {
        //   key: "registerDate",
        //   label: "Registration Date",
        //   filterable: true,
        //   sortable: true,
        // },
        {
          key: "warrantyStatus",
          label: "Registration Status",
        },
        // {
        //   key: "flagExpiry",
        //   label: "Flag Expiry",
        //   filterable: true,
        //   sortable: true,
        // },
        // {
        //   key: "generalWarrantyExpiry",
        //   label: "General Warranty Expiry",
        //   filterable: true,
        //   sortable: true,
        // },
        // {
        //   key: "compressorWarrantyExpiry",
        //   label: "Compressor Warranty Expiry",
        //   filterable: true,
        //   sortable: true,
        // },
        {
          key: "generalWarrantyEndDate",
          label: "General Warranty End",
        },
        {
          key: "residentialWarrantyEndDate",
          label: "Residential Warranty End",
        },
        {
          key: "compressorWarrantyEndDate",
          label: "Compressor Warranty End",
        },
        { key: "postal", label: "Postal" },
        {
          key: "mergeAddress",
          label: "Address",
          thStyle: { minWidth: "250px" },
        },
        // {
        //   key: "registerStatus",
        //   label: "Status",
        //   filterable: true,
        //   sortable: true,
        // },
        {
          key: "name",
          label: "Project Name",
        },
        {
          key: "topDate",
          label: "TOP / Commissioning",
        },
        {
          key: "developer",
          label: "Developer / Applied Cust",
        },

        { key: "createdAt", label: "Registration Date" },
      ],

      filter: {
        "Registration Date": {
          field: "createdAt",
          callback: (value) => {
            return dateFormat(value);
          },
        },
        Certificate: "certNo",
        Model: "itemModel",
        Barcode: "barcode",
        "Installation Date": {
          field: "installationDate",
          callback: (value) => {
            if (value) {
              return dateFormat(value);
            } else {
              return "";
            }
          },
        },
        "Invoice Date": {
          field: "invoiceDate",
          callback: (value) => {
            if (value) {
              return dateFormat(value);
            } else {
              return "";
            }
          },
        },
        "Distribution Channel": "industryType",
        // 'Dealer Code'       : 'dealerCode',
        "Dealer Code": {
          field: "dealerCode",
          callback: (value) => ` ​${value}`,
        },
        "Dealer Name": "dealerName",
        Customer: "fullName",
        Email: "email",
        Serial: "serial",
        "DO No.": "sapDo",
        "Installation Address": "address",
        "Building Type": "buildingType",
        "Contractor Name": "installerName",
        Pcode: "pcode",
        "Product Hierarchy": {
          field: "phlvl1",
          callback: (value) => ` ​${value}`,
        },
        "Project Name": "name",
        "Top Date": {
          field: "topDate",
          callback: (value) => {
            if (value) {
              return dateFormat(value);
            } else {
              return "";
            }
          },
        },
        "Developer Name": "developer",
        "Warranty Status": "warrantyStatus",
        "flag Expiry": "flagExpiry",
        "General Warranty Expiry": {
          field: "generalWarrantyEndDate",
          callback: (value) => {
            return dateFormat(value);
          },
        },
        "Residential Warranty End Date": {
          field: "residentialWarrantyEndDate",
          callback: (value) => {
            if (value) {
              return dateFormat(value);
            } else {
              return "";
            }
          },
        },
        "Compressor Warranty Expiry": {
          field: "compressorWarrantyEndDate",
          callback: (value) => {
            let date = value ? dateFormat(value) : "";
            return date;
          },
        },
        "General Additional Period (Month)": "generalWarrantyDuration",
        "Compressor Additional Period (Month)": "compressorWarrantyDuration",
      },
    };
  },

  mounted() {
    this.$store.dispatch("TermCondition/fetchTermCondition");
    setTimeout(() => {
      axios
        .get(`/sales-and-purchase/drcc/term-by/applied equipment warranty/`)
        .then((res) => {
          // console.log("res__data", res.data);
          this.form = res.data.data;
        })
        .catch((err) => console.log({ err }));
    }, 300);
    this.getAllWarranty({
      year: this.reactiveYear,
      month: this.reactiveMonth,
      page: 1,
    })
      .then((data) => {
        this.busyLoadingWarranty = false;
        // console.log('data fetch',data);
        this.processMetadata(data.metadata);
        this.allWarranties = data.data;
      })
      .catch((err) => {
        console.log({ err });
      });
  },
  created() {
    this.getFilterPaperless("paperless-list");
    this.currentPage = this.reactiveCurrentPage;
    this.warrantyType = this.reactiveWarrantyType;
    this.year = this.reactiveYear;
    this.month = this.reactiveMonth;

    let now = new Date();
    let year = now.getFullYear();

    this.yearOptions.push({ value: year, text: year });

    for (let i = 1; i < 8; i++) {
      this.yearOptions.push({ value: year - i, text: year - i });
    }
  },
  methods: {
    ...mapActions({
      getAllWarranty: "warranty/getAllWarranty",
      updateFilter: "filter/updateFilter",
      getFilterPaperless: "filter/getFilter",
      getProjectWarranty: "projectWarranty/getProject",
      getEquipmentWarranty: "equipmentWarranty/getEquipment",
    }),
    fireFiltering(key, value) {
      this.savePaperlessFilter(key, value);
      this.savePaperlessFilter("page", 1);

      let filter = this.filterPaperless;
      // console.log("filter", filter);

      this.busyLoadingWarranty = true;
      this.getAllWarranty({
        ...filter,
        // lastRegisterDate:this.metadata.lastRegisterDate
      }).then((data) => {
        // console.log("datat", data);
        this.busyLoadingWarranty = false;
        this.processMetadata(data.metadata);
      });
    },
    onPageChange(page) {
      this.savePaperlessFilter("page", page);

      let filter = this.filterPaperless;
      // console.log({ filter });

      this.busyLoadingWarranty = true;
      this.getAllWarranty({
        ...filter,
        // lastRegisterDate:this.metadata.lastRegisterDate
      }).then((data) => {
        this.busyLoadingWarranty = false;
        this.processMetadata(data.metadata);
      });
    },
    savePaperlessFilter(key, value) {
      let tempFilter = this.filterPaperless;
      // console.log("tempFilter", tempFilter);
      if (value === null) {
        tempFilter[key] = undefined;

        if (key === "year") {
          tempFilter["month"] = undefined;
          this.month = null;
        }
      } else {
        tempFilter[key] = value;
      }

      this.updateFilter({
        name: "paperless-list",
        data: { ...tempFilter },
      });
    },
    doFilter() {
      this.savePaperlessFilter(this.selectedField, this.toFilter.trim());
      this.savePaperlessFilter("page", 1);

      let filter = this.filterPaperless;

      if (this.toFilterModel) {
        filter["itemModel"] = this.toFilterModel;
      }

      if (this.toFilterSerial) {
        filter["serial"] = this.toFilterSerial;
      }
      if (this.toFilterPhlvl1) {
        filter["phlvl1"] = this.toFilterPhlvl1;
      }

      if (!this.toFilter) {
        delete filter[this.selectedField];
      }
      // console.log("filter", filter);
      this.busyLoadingWarranty = true;
      this.getAllWarranty({
        ...filter,
        // lastRegisterDate:this.metadata.lastRegisterDate,
        page: 1,
      }).then((data) => {
        // console.log("datanya", data);
        this.busyLoadingWarranty = false;
        this.processMetadata(data.metadata);
      });
    },
    dropDownMenuClick(event) {
      let tempFilter = this.filterPaperless;
      let localField = this.selectedField;

      delete tempFilter[localField];

      this.updateFilter({
        name: "paperless-list",
        data: { ...tempFilter },
      });

      this.selectedFilter = event.target.text;
      this.selectedField = event.target.getAttribute("field");
    },
    clearFilter() {
      // console.log("this.filterPaperless", this.filterPaperless);
      let tempFilter = this.filterPaperless;
      let localField = this.selectedField;

      this.selectedField = "fullName";
      this.selectedFilter = "Name";
      this.toFilter = "";
      this.toFilterModel = "";
      this.toFilterSerial = "";
      this.toFilterPhlvl1 = "";

      // console.log(localField)

      delete tempFilter[localField];
      delete tempFilter["itemModel"];
      delete tempFilter["serial"];
      delete tempFilter["phlvl1"];

      // console.log("tempFilter_clear", tempFilter);
      this.updateFilter({
        name: "paperless-list",
        data: { ...tempFilter },
      });

      this.getAllWarranty({
        ...tempFilter,
        status: "Registered",
      }).then((data) => {
        this.busyLoadingWarranty = false;
        this.processMetadata(data.metadata);
      });
    },
    processMetadata(metadata) {
      // console.log("metadata", metadata);
      this.totalData = metadata.totalData;
      this.metadata.totalPages = metadata.totalPages;
      this.metadata.totalData = metadata.totalData;
      this.metadata.lastRegisterDate = metadata.lastRegisterDate;
    },
    formatCreatedAt(date) {
      let dateUpdated = date ? dateFormat(date) : "";
      return dateUpdated;
    },
    formatTopDate(date) {
      let dateUpdated = date ? dateFormat(date) : "";
      return dateUpdated;
    },
    compressorDate(date) {
      let dateUpdated = date ? dateFormat(date) : "-";
      return dateUpdated;
    },
    generalDate(date) {
      let dateUpdated = date ? dateFormat(date) : "";
      return dateUpdated;
    },

    resetDownload() {
      this.downPage = 0;
    },

    // Method For Download Report All Warranty
    async openDownloadModal() {
      //filter
      let filter = this.filterPaperless;
      if (this.toFilterModel) {
        filter["itemModel"] = this.toFilterModel;
      }

      if (this.toFilterSerial) {
        filter["serial"] = this.toFilterSerial;
      }
      if (this.toFilterPhlvl1) {
        filter["phlvl1"] = this.toFilterPhlvl1;
      }

      if (!this.toFilter) {
        delete filter[this.selectedField];
      }

      this.downPage = 0;
      this.dataDownload = 0;
      this.warrantyToDownload = [];
      this.promptDownload = true;
      let page = this.downPage;
      let dataDownload = this.dataDownload;
      while (dataDownload < this.metadata.totalData) {
        let dataProcess = [];
        let finalData = [];
        page++;
        this.downPage = page;

        await this.getAllWarranty({
          ...filter,
          year: this.year,
          month: this.month,
          // lastRegisterDate:this.metadata.lastRegisterDate,
          page: page,
          entry: 400,
        }).then((res) => {
          // this.warrantyToDownload=data.data;
          dataProcess = res.data.data;
          // console.log(dataProcess)

          // split into each item
          for (let i = 0; i < dataProcess.length; i++) {
            const element = dataProcess[i];
            if (element == null) {
              console.log("ada null");
              let address = "";
              let address2 = "";
              let address3 = "";
              let buildingName = "";
              let buildingType = "";
              // console.log(`element ke ${i}`,dataProcess[i])
              finalData.push({
                certNo: "",
                fullName: "",
                salutation: "",
                ref: "",

                address: `${address} ${address2} ${address3} ${buildingName}`,
                buildingType: buildingType,
                customerType: "",
                email: "",
                phone: "",
                postal: "",
                registerType: "",
                installerName: "",
                installationDate: "",
                warrantyStatus: "",
                flagExpiry: "",
                purchaseDate: "",
                companyName: "",

                sapDo: "",
                dateDO: "",
                dealerCode: "",
                dealerName: "",

                pcode: "",
                phlvl1: "",
                generalWarrantyDuration: "",
                compressorWarrantyDuration: "",
                residentialWarrantyEndDate: "",
                generalWarrantyEndDate: "",
                compressorWarrantyEndDate: "",
                invoiceDate: "",
                industryType: "",

                barcode: "",
                name: "",
                topDate: "",
                itemModel: "",
                developer: "",
                serial: "",
                remarks: "",
                createdAt: "",
              });
              // console.log({finalData});
              continue;
            }
            let address = element.address ? element.address : "";
            let address2 = element.address2 ? element.address2 : "";
            let address3 = element.address3 ? element.address3 : "";
            let buildingName = element.buildingName ? element.buildingName : "";
            let buildingType = element.buildingType ? element.buildingType : "";

            finalData.push({
              id: element.id,
              certNo: element.certNo,
              fullName: element.fullName,
              salutation: element.salutation,

              address: `${address} ${address2} ${address3} ${buildingName}`,
              buildingType: buildingType,
              customerType: element.customerType,
              email: element.email,
              phone: element.phone,
              postal: element.postal,
              postalCode: element.postalCode,
              registerType: element.registerType,
              installerName: element.installerName,
              installationDate: element.installationDate,
              warrantyStatus: element.warrantyStatus,
              flagExpiry: element.flagExpiry,
              purchaseDate: element.purchaseDate,
              companyName: element.companyName,

              sapDo: element.sapDo,
              dateDO: element.dateDO,
              dealerCode: element.dealerCode,
              dealerName: element.dealerName,
              phlvl1: element.phlvl1,
              pcode: element.pcode,
              generalWarrantyDuration: element.generalWarrantyDuration,
              compressorWarrantyDuration: element.compressorWarrantyDuration,
              residentialWarrantyEndDate: element.residentialWarrantyEndDate,
              generalWarrantyEndDate: element.generalWarrantyEndDate,
              compressorWarrantyEndDate: element.compressorWarrantyEndDate,
              invoiceDate: element.invoiceDate,
              industryType: element.industryType,
              ref: element.ref,
              barcode: element.barcode,
              name: element.name,
              topDate: element.topDate,
              itemModel: element.itemModel,
              developer: element.developer,
              serial: element.serial,
              remarks: element.remarks,
              createdAt: element.createdAt,
            });
          }
          this.warrantyToDownload.push(...finalData);
          dataDownload += dataProcess.length;
          this.dataDownload = dataDownload;
        });
      }
    },
    async letterDev(id) {
      let getData = {};
      await this.getProjectWarranty(id)
        .then((res) => {
          getData = res;
        })
        .catch((e) => {
          console.log({ e });
        });

      function dateTransformWithDate(date) {
        let dates = new Date(date);
        let options = {
          weekday: "long",
          day: "numeric",
          month: "long",
          year: "numeric",
          timeZone: "Asia/Singapore",
        };
        let formattedDate = dates
          .toLocaleDateString("en-SG", options)
          .replace(/,/g, ""); // Remove commas
        return formattedDate;
      }
      // let data = this.getDataProject
      let cert = `${getData.refNum}D`;
      // let monthText = getData.warrantyDuration === 15 ?'fifteen':'eighteen'
      let monthText = "";
      switch (getData.warrantyDuration) {
        case 15:
          monthText = "fifteen";
          break;
        case 18:
          monthText = "eighteen";
          break;
        default:
          monthText = "twelve";
          break;
      }
      let topDate = new Date(getData.topDate);
      let topDateString = new Date(getData.topDate);
      let transformTopDate = dateTransformWithDate(topDateString);
      let devEndDate = new Date(topDate);
      topDate.setDate(topDate.getDate() - 1);
      devEndDate = topDate.setMonth(
        topDate.getMonth() + getData.warrantyDuration
      );
      let transformDevEndDate = dateTransformWithDate(devEndDate);

      // var pdfMake = require("pdfmake/build/pdfmake.js");
      if (pdfMake.vfs == undefined) {
        // var pdfFonts = require("./customVfs.js");
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }
      let date = new Date().toISOString().substring(0, 10);

      function dateTransform(date) {
        let dates = new Date(date);
        let options = {
          day: "numeric",
          month: "long",
          year: "numeric",
          timeZone: "Asia/Singapore",
        };
        let formattedDate = dates
          .toLocaleDateString("en-SG", options)
          .replace(/,/g, ""); // Remove commas
        return formattedDate;
      }

      // optimal width header 460
      var docDefinition = {
        pageMargins: [70, 120, 70, 50],
        header: function(currentPage, pageCount, pageSize) {
          return [
            {
              image: `${header}`,
              alignment: "center",
              width: 460,
              margin: [0, 50, 0, 0],
            },
          ];
        },
        footer: function(currentPage, pageCount) {
          return [
            {
              image: `${footer}`,
              width: 400,
              alignment: "center",
              margin: [0, 0, 0, 0],
            },
          ];
        },

        content: [
          { text: `Ref    : ${cert}`, style: "subheader" },
          { text: `Date  : ${date}` },
          { text: `\n` },
          { text: `\n` },
          { text: `${getData.developer}` },
          { text: `${getData.devAddress1} ${getData.devAddress2}` },
          { text: `${getData.devCountry} ${getData.devPostalCode}` },

          { text: `\n` },
          { text: `\n` },

          { text: `Dear Sirs \n` },
          { text: `\n` },

          {
            text: `WARRANTY FOR DAIKIN AIRCONDITIONING EQUIPMENT FOR ${getData.address.toUpperCase()} ${getData.country.toUpperCase()} ${getData.postalCode.toUpperCase()} \n`,
            fontSize: 12,
            bold: true,
          },
          { text: `\n` },
          {
            text: `We are pleased to advise the warranty for the airconditioning equipment installed at the above project shall commence from ${transformTopDate} with ${monthText} (${getData.warrantyDuration}) months warranty for the equipment.  
                    The warranty of all parts shall expire on ${transformDevEndDate}. \n`,
          },
          { text: `\n` },

          {
            text: `Please refer to Annex A for the list of items covered in this warranty. \n`,
          },
          { text: `\n` },

          {
            text: `Terms and Conditions of Warranty \n`,
            decoration: "underline",
            fontSize: 13,
            bold: true,
          },
          { text: `\n` },
          {
            text: `The warranty period for the air-conditioner is as follows –\n`,
          },
          { text: `\n` },
          {
            ol: this.terms,
          },
          { text: "\n" },
          { text: "\n" },
          { text: "General Manager for sales\n" },
          { text: "\n" },
          { text: "\n" },
          { text: "\n" },
          {
            text:
              "This is a system generated letter. No Signature is required.\n",
          },

          { text: "Annex A \n", bold: true, pageBreak: "before", fontSize: 13 },
          {
            style: "tableExample",
            color: "#444",
            table: {
              widths: ["20%", "20%", "30%", "30%"],
              // keepWithHeaderRows: 1,
              body: [
                [
                  {
                    text: "Model",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                    bold: true,
                  },
                  {
                    text: "Serial No",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                    bold: true,
                  },
                  {
                    text: "Date of Warranty Expiry",
                    style: "tableHeader",
                    alignment: "center",
                    colSpan: 2,
                    bold: true,
                  },
                  {},
                ],
                [
                  {},
                  {},
                  { text: "Other Parts", bold: true, alignment: "center" },
                  { text: "Compressor", bold: true, alignment: "center" },
                ],
              ],
            },
          },
        ],
      };

      for (let i = 0; i < getData.itemsProject.length; i++) {
        const el = getData.itemsProject[i];
        let otherPart = dateTransform(el.generalEndWarranty);
        let compressor = el.compressorEndWarranty
          ? dateTransform(el.compressorEndWarranty)
          : "-";
        docDefinition.content[30].table.body.push([
          { text: `${el.itemModel}`, alignment: "center" },
          { text: `${el.serial}`, alignment: "center" },
          { text: `${otherPart}`, alignment: "center" },
          { text: `${compressor}`, alignment: "center" },
        ]);
      }
      pdfMake
        .createPdf(docDefinition)
        .download(`Daikin Warranty Letter-${cert}.pdf`);
    },
    async letterResident(id) {
      let getData = {};
      await this.getProjectWarranty(id)
        .then((res) => {
          getData = res;
        })
        .catch((e) => {
          console.log({ e });
        });

      function dateTransformWithDate(date) {
        let dates = new Date(date);
        let options = {
          weekday: "long",
          day: "numeric",
          month: "long",
          year: "numeric",
          timeZone: "Asia/Singapore",
        };
        let formattedDate = dates
          .toLocaleDateString("en-SG", options)
          .replace(/,/g, ""); // Remove commas
        return formattedDate;
      }
      // // let data = this.getDataProject
      // console.log("dur", getData.itemsProject[0].residentialDuration);
      let cert = `${getData.refNum}R`;
      let resDur = getData.itemsProject[0].residentialDuration;
      let topDate = new Date(getData.topDate);
      let topDateString = new Date(getData.topDate);
      let transformTopDate = dateTransformWithDate(topDateString);

      let devEndDate = new Date(topDate);
      topDate.setDate(topDate.getDate() - 1);
      devEndDate = topDate.setMonth(
        topDate.getMonth() + getData.itemsProject[0].residentialDuration
      );
      let transformDevEndDate = dateTransformWithDate(devEndDate);

      // var pdfMake = require("pdfmake/build/pdfmake.js");
      if (pdfMake.vfs == undefined) {
        // var pdfFonts = require("./customVfs.js");
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }
      let date = new Date().toISOString().substring(0, 10);

      function dateTransform(date) {
        return new Date(date).toUTCString().substring(5, 16);
      }

      // function number to words pronounce
      const ones = [
        "",
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
      ];
      const tens = [
        "",
        "",
        "twenty",
        "thirty",
        "forty",
        "fifty",
        "sixty",
        "seventy",
        "eighty",
        "ninety",
      ];
      const teens = [
        "ten",
        "eleven",
        "twelve",
        "thirteen",
        "fourteen",
        "fifteen",
        "sixteen",
        "seventeen",
        "eighteen",
        "nineteen",
      ];

      function convert_millions(num) {
        num = Number(num);
        if (num >= 1000000) {
          return (
            convert_millions(Math.floor(num / 1000000)) +
            " million " +
            convert_thousands(num % 1000000)
          );
        } else {
          return convert_thousands(num);
        }
      }

      function convert_thousands(num) {
        num = Number(num);
        if (num >= 1000) {
          return (
            convert_hundreds(Math.floor(num / 1000)) +
            " thousand " +
            convert_hundreds(num % 1000)
          );
        } else {
          return convert_hundreds(num);
        }
      }

      function convert_hundreds(num) {
        num = Number(num);
        if (num > 99) {
          return (
            ones[Math.floor(num / 100)] + " hundred " + convert_tens(num % 100)
          );
        } else {
          return convert_tens(num);
        }
      }

      function convert_tens(num) {
        num = Number(num);
        if (num < 10) return ones[num];
        else if (num >= 10 && num < 20) return teens[num - 10];
        else {
          return tens[Math.floor(num / 10)] + " " + ones[num % 10];
        }
      }

      function convert(num) {
        num = Number(num);
        if (num == 0) return "zero";
        else return convert_millions(num);
      }
      //end of convertion code

      let monthText = convert(resDur);

      // optimal width header 460
      var docDefinition = {
        pageMargins: [70, 120, 70, 50],
        header: function(currentPage, pageCount, pageSize) {
          return [
            {
              image: `${header}`,
              alignment: "center",
              width: 460,
              margin: [0, 50, 0, 0],
            },
          ];
        },
        footer: function(currentPage, pageCount) {
          return [
            {
              image: `${footer}`,
              width: 400,
              alignment: "center",
              margin: [0, 0, 0, 0],
            },
          ];
        },

        content: [
          { text: `Ref    : ${cert}`, style: "subheader" },
          { text: `Date  : ${date}` },
          { text: `\n` },
          { text: `\n` },
          { text: `THE RESIDENTS` },
          { text: `${getData.address}` },
          { text: `${getData.country} ${getData.postalCode}` },

          { text: `\n` },
          { text: `\n` },

          { text: `Dear Sir/Madam \n` },
          { text: `\n` },

          {
            text: `WARRANTY FOR DAIKIN AIRCONDITIONING EQUIPMENT FOR ${getData.address.toUpperCase()} ${getData.country.toUpperCase()} ${getData.postalCode.toUpperCase()} \n`,
            fontSize: 12,
            bold: true,
          },
          { text: `\n` },
          {
            text: `We are pleased to advise the warranty for the airconditioning equipment installed at the above project shall commence from ${transformTopDate} with ${monthText} (${getData.itemsProject[0].residentialDuration}) months warranty for the equipment.  
                    The warranty of all parts shall expire on ${transformDevEndDate}. \n`,
          },
          { text: `\n` },
          {
            text: `Terms and Conditions of Warranty \n`,
            decoration: "underline",
            fontSize: 13,
            bold: true,
          },
          { text: `\n` },
          {
            text: `The warranty period for the air-conditioner is as follows –\n`,
          },
          { text: `\n` },
          {
            ol: this.terms,
          },
          { text: "\n" },
          { text: "\n" },
          {
            text:
              "This is a system generated letter. No Signature is required.\n",
          },
          { text: "\n" },
          { text: "\n" },
          { text: "\n" },
          { text: "General Manager for sales\n" },
        ],
      };

      pdfMake
        .createPdf(docDefinition)
        .download(`Daikin Warranty Letter-${cert}.pdf`);
    },

    async letterEquipment(id) {
      let getData = {};
      await this.getEquipmentWarranty(id)
        .then((res) => {
          getData = res;
          console.log(res);
        })
        .catch((e) => {
          console.log({ e });
        });

      function dateTransformWithDate(date) {
        let dates = new Date(date);
        let options = {
          weekday: "long",
          day: "numeric",
          month: "long",
          year: "numeric",
          timeZone: "Asia/Singapore",
        };
        let formattedDate = dates
          .toLocaleDateString("en-SG", options)
          .replace(/,/g, ""); // Remove commas
        return formattedDate;
      }
      // let data = this.getDataEquipment
      console.log("pro", getData);
      let cert = `${getData.refNum}`;
      // let monthText = getData.warrantyDuration === 15 ?'fifteen':'eighteen'
      let monthText = "";
      switch (getData.warrantyDuration) {
        // case 15:
        //   monthText = 'fifteen'
        //   break;
        case 18:
          monthText = "eighteen";
          break;

        default:
          monthText = "twelve";
          break;
      }
      let topDate = new Date(getData.topDate);
      let topDateString = new Date(getData.topDate);
      let transformTopDate = dateTransformWithDate(topDateString);
      let devEndDate = new Date(topDate);
      topDate.setDate(topDate.getDate() - 1);
      devEndDate = topDate.setMonth(
        topDate.getMonth() + getData.warrantyDuration
      );
      let transformDevEndDate = dateTransformWithDate(devEndDate);

      // var pdfMake = require("pdfmake/build/pdfmake.js");
      if (pdfMake.vfs == undefined) {
        // var pdfFonts = require("./customVfs.js");
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }
      let date = new Date().toISOString().substring(0, 10);

      function dateTransform(date) {
        let dates = new Date(date);
        let options = {
          day: "numeric",
          month: "long",
          year: "numeric",
          timeZone: "Asia/Singapore",
        };
        let formattedDate = dates
          .toLocaleDateString("en-SG", options)
          .replace(/,/g, ""); // Remove commas
        return formattedDate;
      }

      // optimal width header 460
      var docDefinition = {
        pageMargins: [70, 120, 70, 50],
        header: function(currentPage, pageCount, pageSize) {
          return [
            {
              image: `${header}`,
              alignment: "center",
              width: 460,
              margin: [0, 50, 0, 0],
            },
          ];
        },
        footer: function(currentPage, pageCount) {
          return [
            {
              image: `${footer}`,
              width: 400,
              alignment: "center",
              margin: [0, 0, 0, 0],
            },
          ];
        },

        content: [
          { text: `Ref    : ${cert}`, style: "subheader" },
          { text: `Date  : ${date}` },
          { text: `\n` },

          { text: `${getData.developer}` },
          { text: `${getData.address}` },
          { text: `${getData.country} ${getData.postalCode}` },

          { text: `\n` },
          { text: `\n` },

          { text: `Dear Sirs \n` },
          { text: `\n` },

          {
            text: `WARRANTY FOR DAIKIN AIRCONDITIONING EQUIPMENT FOR ${getData.address.toUpperCase()} ${getData.country.toUpperCase()} ${getData.postalCode.toUpperCase()} \n`,
            fontSize: 12,
          },
          { text: `\n` },
          {
            text: `We are pleased to advise the warranty for the airconditioning equipment installed at the above address shall commence on  ${transformTopDate}. \n`,
          },
          { text: `\n` },

          {
            text: `Please refer to Annex A for the list of items covered in this warranty. \n`,
          },
          { text: `\n` },

          {
            text: `Terms and Conditions of Warranty \n`,
            decoration: "underline",
            fontSize: 13,
            bold: true,
          },
          // {text: `Defect Liability Period and/or Warranty (“Warranty"): \n`,fontSize:13, bold:true},

          { text: `\n` },
          {
            text: `The warranty period for the chiller, air handling unit and chilled water fan coil (“Applied Products”)  is as follows –\n`,
          },
          { text: `\n` },
          {
            ol: this.termsEquipment,
          },
          { text: "\n" },
          { text: "\n" },
          {
            text:
              "This is a system generated letter. No Signature is required.\n",
          },

          { text: "Annex A \n", bold: true, pageBreak: "before", fontSize: 13 },
          {
            style: "tableExample",
            color: "#444",
            table: {
              widths: ["30%", "20%", "25%", "25%"],
              // keepWithHeaderRows: 1,
              body: [
                [
                  {
                    text: "Model",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                    bold: true,
                  },
                  {
                    text: "Serial No",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                    bold: true,
                  },
                  {
                    text: "Date of Warranty Expiry",
                    style: "tableHeader",
                    alignment: "center",
                    colSpan: 2,
                    bold: true,
                  },
                  {},
                ],
                [
                  {},
                  {},
                  { text: "Other Parts", bold: true, alignment: "center" },
                  { text: "Compressor", bold: true, alignment: "center" },
                ],
              ],
            },
          },
        ],
      };

      for (let i = 0; i < getData.items.length; i++) {
        const el = getData.items[i];
        let otherPart = dateTransform(el.generalEndWarranty);
        let compressor = el.compressorEndWarranty
          ? dateTransform(el.compressorEndWarranty)
          : "-";
        docDefinition.content[25].table.body.push([
          { text: `${el.itemModel}`, alignment: "center" },
          { text: `${el.serial}`, alignment: "center" },
          { text: `${otherPart}`, alignment: "center" },
          { text: `${compressor}`, alignment: "center" },
        ]);
      }
      pdfMake
        .createPdf(docDefinition)
        .download(`Daikin Warranty Letter-${cert}.pdf`);
    },

    detailWarranty(row) {
      // console.log("row", row);
      let routeData;
      // if (row.item.topDate !== undefined) {
      //   routeData = this.$router.push({
      //     name: "all-warranty-project",
      //     query: { cert: row.item.certNo },
      //   });
      // } else {
      // }
      routeData = this.$router.resolve(`/detail-warranty/${row.item.certNo}`);
      window.open(routeData.href, "_blank");
    },
  },
  computed: {
    ...mapState({
      allWarranty: (state) => {
        // console.log("state.warranty.allWarranty", state.warranty);
        return state.warranty.allWarranty;
      },
      termsCondition: (state) => state.TermCondition,

      progressDownload() {
        let downloaded = this.warrantyToDownload.length;
        let dataToDownload = this.metadata.totalData;
        return Math.ceil((downloaded / dataToDownload) * 100);
      },
      filterPaperless: (state) => {
        if (state.filter.filterContainer["paperless-list"] !== undefined) {
          let paperlessFilter = state.filter.filterContainer["paperless-list"];
          return paperlessFilter;
        }
        return {};
      },
    }),
    terms() {
      let final = [];
      let primary = this.termsCondition.TermCondition[0].primary;
      let secondary = this.termsCondition.TermCondition[0].secondary;
      for (let i = 0; i < primary.length; i++) {
        let temp = [];
        temp.push(primary[i]);
        if (secondary[i].length !== 0) {
          temp.push({ ul: secondary[i] });
        }
        final.push(temp);
      }
      return final;
    },
    termsEquipment() {
      let final = [];
      let primary = this.form.primary;
      let secondary = this.form.secondary;
      for (let i = 0; i < primary.length; i++) {
        let temp = [];
        if (secondary[i].length === 0) {
          temp.push({ text: primary[i], margin: [0, 10, 0, 5] });
        } else {
          temp.push({ text: primary[i], margin: [0, 10, 0, 0] });
        }
        if (secondary[i].length !== 0) {
          temp.push({ ul: secondary[i] });
        }
        final.push(temp);
      }
      return final;
    },
    monthYearQueryExist() {
      if (this.month == null) {
        return true;
      }
      if (this.year == null) {
        return true;
      }
      return false;
    },
    reactiveCurrentPage: {
      get() {
        if (this.filterPaperless.currentPage === undefined) {
          return 1;
        }

        return this.filterPaperless.currentPage;
      },
      set(newVal) {
        // console.log("newVal", newVal);
        this.updateFilter({
          name: "paperless-list",
          data: {
            currentPage: newVal,
          },
        });

        this.currentPage = newVal;
      },
    },
    reactiveWarrantyType() {
      if (this.filterPaperless.warrantyType !== undefined) {
        return this.filterPaperless.warrantyType;
      }

      return null;
    },
    reactiveYear() {
      if (this.filterPaperless.year !== undefined) {
        return this.filterPaperless.year;
      }

      return null;
    },
    reactiveMonth() {
      if (this.filterPaperless.month !== undefined) {
        return this.filterPaperless.month;
      }

      return null;
    },
  },
};
</script>

<style scoped>
.paperless-drop {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.paperless-drop label {
  font-weight: bold;
  text-align: left;
}

.filter-responsive {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.filter-responsive button {
  margin: 0 0 0 5px;
}
.d-flex {
  margin-top: auto; /* Ini akan membuat tombol 'Download' sejajar dengan form-select */
}

.filter-grid {
  display: grid;
  grid-template-columns: auto auto auto;
  /* grid-template-columns: 20%; */
  column-gap: 10px;
}

@media only screen and (max-width: 767px) {
  .paperless-drop {
    width: 100%;
    margin-right: 10px;
  }

  .filter-grid {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    /* Sesuaikan nilai margin-top sesuai dengan kebutuhan */
    margin-top: 10px;
  }
}

@media screen and (max-width: 480px) {
  .filter-responsive {
    flex-direction: column;
    justify-content: none;
  }

  .filter-responsive button {
    margin: 5px 0 0 0;
    width: 100px;
  }
}
</style>
